import React from 'react'
// import { getSiteMaps } from 'lib/get-site-maps'
import { resolveNotionPage } from 'lib/resolve-notion-page'
import { NotionPage } from 'components'
import { PageProps } from 'lib/types'
import { evaluatePageRevalidation } from 'lib/utils'
import * as Sentry from '@sentry/nextjs'
import Head from 'next/head'; // Correct import
import {getSiteCustomCode,getPageBySlug} from "../../../lib/get-site-for-domain";
import parse from "html-react-parser";
import { cleanCode } from "../../../lib/embeds";
export const getStaticProps = async (context) => {
  const domain = context.params.site
  const urlArray = context.params.pageId
  const subPage =urlArray[0]
   console.log("**********context*****",context)
  try {
    const props = (await resolveNotionPage(domain, urlArray)) as PageProps
    let siteData;
    let pageData;
    if ((domain && !domain.startsWith('localhost')) ){
      siteData = await getSiteCustomCode(domain);
      if (subPage){
        pageData = await getPageBySlug(siteData.siteId, subPage)
        if (pageData){
        siteData.name =pageData.title ? pageData.title : siteData.name
        siteData.description =pageData.description ? pageData.description :siteData.description
        siteData.socialMedia =pageData.socialMedia ? pageData.socialMedia : siteData.socialMedia
        siteData.subPage = subPage
        }
      }
    }
    else{
      siteData=  await getSiteCustomCode("unlimted.thesimple.ink");
    }
    const link = redirectSuperLink(props)

    if (props.redirect) {
      console.log('redirecting to', props.redirect.destination)
      return {
        redirect: {
          destination: `/${props.redirect.destination}`,
          from: 'getStaticProps - _sites/[site]/[...pageId].tsx'
        }
      }
    }

    if (link) {
      return {
        redirect: {
          destination: link
        }
      }
    }

    const revalidateNr = evaluatePageRevalidation(props)
    return {props: { ...props, siteData }, revalidate: revalidateNr }
  } catch (err) {
    if (err.message.includes('There is no matching site')) {
      return {
        redirect: {
          destination: 'https://create.thesimple.ink/404-page'
        }
      }
    }
    if (err.message.includes('Notion page not found')) {
      return {
        redirect: {
          destination: 'https://create.thesimple.ink/404-page-websites'
        }
      }
    }

    // we don't want to publish the error version of this page, so
    // let next.js know explicitly that incremental SSG failed
    console.error('page error', domain, urlArray, err)
    Sentry.captureException(err, {
      extra: {
        domain,
        urlArray,
        from: 'getStaticProps'
      }
    })
    throw err
  }
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true
  }
}

// export default function NotionDomainDynamicPage(props) {
//   props = { ...props, isSubPage: true }
//
//   const content = <NotionPage {...props} />
//   return typeof content === 'object' ? content : null
// }

export default function NotionDomainDynamicPage(props) {
  props = { ...props, isSubPage: true }
  const { siteData } = props; // Extract site and keep the rest
  const content = <NotionPage {...props} />;
  // Check if content is an object
  if (typeof content === 'object') {
    return (
        <>
          <Head>
            <title>{siteData?.name}</title>
            <meta property='og:title' content={siteData?.name} />
            <meta property='og:site_name' content={siteData?.name} />

            <meta name='twitter:title' content={siteData?.name} />
            <meta property='twitter:domain' content={siteData?.domain} />
            <meta httpEquiv='content-language' content={siteData?.siteLanguageCode} />

            <meta name='description' content={siteData?.description} />
            <meta property='og:description' content={siteData?.description} />
            <meta name='twitter:description' content={siteData?.description} />

            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image' content={siteData?.socialMedia} />
            <meta property='og:image' content={siteData?.socialMedia} />

            <meta name='twitter:card' content='summary' />

            <meta property='og:url' content={`https://${siteData?.domain}`} />
            <meta property='twitter:url' content={`https://${siteData?.domain}`} />
            <link href={`https://${siteData?.domain}/${siteData?.subPage}`} rel="canonical"/>
            { siteData?.headCustomCode ? parse(cleanCode(siteData?.headCustomCode)) : ''}
          </Head>
          {content}
        </>
    )
  }
  return null; // Return null if content is not an object
}


function redirectSuperLink(data) {
  if (!data.recordMap) return

  data = data.recordMap

  for (const key of Object.keys(data.block)) {
    const block = data.block[key].value
    if (!block || (block && block.parent_table !== 'collection')) continue
    const parent = data.collection[block.parent_id]

    if (!parent) return
    if (!parent.value) return
    if (!parent.value.schema) return

    // go through parent schema
    for (const sk in parent.value.schema) {
      const sku = parent.value.schema[sk]
      if (!/^simple:link$/i.test(sku.name)) continue

      // redirect the user to the link in question.
      const link = block.properties[sk][0][0]
      return link
    }
  }
}
